// _typography.scss

// @include font-face('fontname', 'light', '300', 'normal');
// @include font-face('fontname', 'Book', '400', 'normal');
// @include font-face('fontname', 'medium', '500', 'normal');
// @include font-face('fontname', 'demi', '600', 'normal');
// @include font-face('fontname', 'bold', '700', 'normal');
// @include font-face('fontname', 'extrabold', '800', 'normal');
// @include font-face('fontname', 'heavy', '900', 'normal');

:root,
:root[mode="md"],
:root[mode="ios"] {
  --ion-default-font: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
  --ion-font-family: var(--ion-default-font);
  font-family: var(--ion-font-family) !important;
}
