@import "abstracts/variables";
@import "abstracts/mixins";
@import "base/typography";

ion-app {
  // --ion-background-color: #E5E5E5;
}

ion-modal {
  --width: fit-content;
  --min-width: 250px;
  --height: fit-content;
  --border-radius: 6px;
  --box-shadow: 0 28px 48px rgba(0, 0, 0, 0.4);
  padding-left: 1rem;
  padding-right: 1rem;
  --ion-backdrop-color: rgba(31, 31, 31, .8);
  background-color: var(--ion-backdrop-color);
  --ion-backdrop-opacity: .8 --backdrop-opacity: var(--ion-backdrop-opacity);
}

ion-button {
  text-transform: none;
  font-size: 1rem;

  &[fill="clear"] {
    font-weight: 500;
  }

  &.btn {
    &-radius {
      --border-radius: 20px;
    }

    &-no-shadow {
      --box-shadow: none;
    }

    &-accent {
      --background: black;
      --color: white
    }
  }
}

.underline {
  text-decoration: underline;
}

ion-list {
  ion-item {
    .item-inner {
      overflow: visible !important;

      .input-wrapper {
        overflow: visible !important;

        ion-label {
          overflow: visible !important;
        }
      }
    }
  }

  &.list-scrollable {
    max-height: 30vh;
    overflow-y: scroll;
  }
}

.onboarding-wrapper {
  .swiper {
    height: 100%;
    &-wrapper {
    }
    &-slide {
      display: flex;
      flex-direction: column;
    }
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 5rem !important;

  .swiper-pagination-bullet {
    --swiper-theme-color: var(--ion-color-primary);
    --swiper-pagination-color: var(--ion-color-primary);
    --swiper-pagination-bullet-inactive-color: var(--ion-color-primary);
    --swiper-pagination-bullet-inactive-opacity: 1;

    &.swiper-pagination-bullet-active {
      &~span {
        background: #F2F2F2;
      }
    }
  }
}

.steps {
  .swiper-pagination-bullets {
    justify-content: left;
    top: 0;
    bottom: unset !important;
    width: 85%;

    .swiper-pagination-bullet {
      font-size: 0.625rem;
      padding: 0.23rem;
      height: var(--swiper-pagination-bullet-height, var(--swiper-pagination-bullet-size, 1.5rem));
      width: var(--swiper-pagination-bullet-width, var(--swiper-pagination-bullet-size, 1.5rem));
      z-index: 1;

      color: white;
      background: black;
      border: 2px solid black;

      &:not(:last-child) {
        margin-right: 2.8rem;
        position: relative;

        &::after {
          content: "";
          border: 1px solid black;
          width: 3rem;
          position: absolute;
          top: 50%;
          left: calc(100% + 2px);
          transform: translateY(-50%);
        }
      }

      &.swiper-pagination-bullet-active {
        color: black;
        background: white;
        border-color: black;

        &::after {
          border-color: #F2F2F2;
        }

        &~span {
          color: #7A7A7A;
          background: #F2F2F2;
          border-color: #F2F2F2;

          &::after {
            border-color: #F2F2F2;
          }
        }
      }

    }
  }
}


.bg-primary {
  --background: var(--ion-color-primary);
  background: var(--ion-color-primary);
}

.bg-light {
  --background: var(--ion-color-light);
  background: var(--ion-color-light);
}

.bg-white {
  --background: var(--ion-color-dark-contrast);
  background: var(--ion-color-dark-contrast);
}

.rounded {
  border-radius: 100%;
  overflow: hidden;
}

ion-card {
  border-radius: .5rem;
  margin-top: 0;
  margin-bottom: 0.2rem;

  &:first-child {
    margin-top: 1rem;
  }

  &.no-shadow {
    box-shadow: none !important;
  }

  &.border {
    border: 1px solid #E8E8E8;
  }

  ion-item {
    --border-color: transparent;
    --padding-start: 1rem;

    &.clear-ion-item {
      --background: none;
      --padding-start: 0;
      --inner-padding-end: 0;
      --min-height: auto;

      &.color-white {
        color: white;
      }
    }

    &.item-interactive {
      --border-width: 0;
      --inner-border-width: 0;
      --show-full-highlight: 0;
      --show-inset-highlight: 0;
    }

    ion-label {
      font-size: 1rem;
      overflow: unset;
    }
  }

  ion-toggle.ios {
    padding-right: 0;
  }

  ion-card-content {
    &::before {
      content: "";
      border-top: 1px solid rgb(232, 232, 232);
      display: block;
      margin: 0 1rem;
      width: calc(100% - 2rem);
    }
  }
}

input {
  background: transparent;
  border: 0;

  &[type="time"] {
    position: relative;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      // display: none;
      // -webkit-appearance: none;
      position: absolute;
      left: 0;
      padding: 0;
      margin: 0;
      width: 100%;
      opacity: 0;
    }
  }
}

ion-select {
  &::part(icon) {
    display: none !important;
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

p {
  ion-text {
    &[color="primary"] {
      font-weight: 600;
      text-decoration: underline;
    }
  }
}


.sent-form {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 11;
  background: var(--ion-color-primary);
  transform: scale(0);
  transition: all .3s ease-in;

  .box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    ion-icon {
      font-size: 7rem;
    }

    h2 {
      font-size: 1.875rem;
    }
  }

  &.active {
    transition-delay: 250ms;
    transition: all .3s ease-in;
    transform: scale(1);
  }
}

.no-before {
  &::before {
    display: none;
  }
}

.d-block {
  display: block;
}

.flag {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  // object-fit: cover;
}

.tableParticipants {
  .col-1 {
    text-align: right;
  }
}

// .map {
//   width: 100%;
//   height: 200px;
//   display: inline-block;

//   .gmnoprint:not([role="menubar"]) {
//     top: 45px !important;
//   }
// }


.map {
  width: 100%;
  height: 200px;
  display: inline-block;

  .gm-control-active.gm-fullscreen-control{
    top: initial !important;
    bottom: 145px !important;
  }

  .gmnoprint {
    &[role="menubar"] {
      top: initial !important;
      bottom: 15px;
    }
    &:not([role="menubar"]) {
      .gmnoprint {
        top: 45px !important;
      }
      &.gm-style-cc{
        top: 0 !important;
      }
      top: initial !important;
      bottom: 140px !important;
    }
  }
}

ion-slides {
  height: 100%;

  .swiper-slide {
    overflow-y: scroll;
    display: block;
  }

}

.swiper-slide:not(.swiper-slide-active) > div {
  display:none;
}

#results, #events, #signup, #archive{
    .col-0:not(.header > .col-0){
      font-size: 10px;
    }
}

ion-segment {
  background: var(--ion-toolbar-background);
  border-radius: 0;
}

input {
  --background: white;
}

.ios {
  .ion-margin-vertical {
    --margin-top: var(--ion-margin, 32px);
    --margin-bottom: unset;
    margin-top: var(--ion-margin, 32px);
    margin-bottom: unset;
  }

  ion-title{
    position: relative;
  }
}

ion-toolbar {
  ion-icon {
    font-size: 24px;
  }

   .sc-ion-searchbar-ios-h {
    padding-top: unset;
    padding-bottom: 0px;
  }
}


ion-header {
  ion-toolbar{
    //&:first-of-type {
    //  padding-top: calc(var(--ion-safe-area-top, 0) + 1rem);
    //}
    ion-title.ios {
      margin-top: 0rem !important;
    }

    ion-buttons ~ ion-title.ios {
      margin-top: 0rem !important;
    }
  }

  ion-searchbar {
    background-color: var(--ion-color-primary-contrast) !important;
    margin-top: -15px;
    z-index: 10;
  }
}

ion-segment.md {
  margin-top: -15px;
}

.showMore{
  background: var(--ion-color-primary);
  margin: auto;
  width: 200px !important;
  border-radius: 20px;
  color: white;
}

#resultpage {
   .col-2, .col-3 {
    text-align: right;
   }
}

#archive {
  .personRow {
    .col-2, .col-3 {
      text-align: right;
     }
  }
}

#personDetails {
  ion-row {
    font-size: 16px;
    padding: 3px;

  }
  .col-0{
    max-width: initial !important;
    flex: 3 !important;
  }
  .col-1 {
    font-weight: bold;
    max-width: initial !important;
    flex: 9 !important;
  }
}

.tableParticipants {
  .col-0 {
    max-width: initial !important;
    flex: 9 !important;
  }
  .col-1 {
    max-width: initial !important;
    flex: 3 !important;
  }
}

ion-tab-bar ion-tab-button ion-label {
  font-size: 10px;
}

#resultpage {
  ion-infinite-scroll{
    margin-bottom: -10px;
    display: block;
  }

  .tableWrapper {
    overflow: hidden !important;
  }
}

.gm-style-mtc ul[role='menu'] {
  top: -40px !important;
  display: none;
}
